import React from "react";

import { Row, Col, Card } from 'react-materialize';

import kit1 from '../../images/kit1.png';

import kit2 from '../../images/kit2.png';

const Produtos = () => (
<Row>
    <div align={"justify"}>
         <Col m={5} s={12}>
            <h5 className="subtitle">Produtos</h5>
            <Card>
               <p>Alarmes, Cercas e CFTV (câmeras de vídeo).</p>
               <br/>
               <p>Todos os nossos produtos possuem garantia dos fornecedores por 1 ano. Possuímos preços competitivos e facilitamos o pagamento em várias parcelas, seja em cheque ou cartões de crédito Visa, Master e Banri.</p>
             
            </Card>
            </Col>
           
            <Col m={7} s={8}>
            <h5 className="subtitle">Kits</h5>
            <Card>
                <Row>
                    <Col>
                        <img src={kit1} className={"imgkits"}/>
                    </Col>

                    <Col>
                        <p><b>KIT ALARME VIA RÁDIO</b></p>

                        <ul className={"browser-default"}>
                            <li>01 Central;</li>
                            <li>10 Sensores;</li>
                            <li>02 Leds;</li>
                            <li>01 Bateria;</li>
                            <li>02 Infras;</li>
                            <li>01 Console;</li>
                            <li>02 Sirenes.</li>
                        </ul>
                </Col>
                </Row>
                </Card>
                <Card>
                <Row>
                    <Col>
                        <img src={kit2} className={"imgkits"}/>
                    </Col>

                    <Col>
                         <p><b>KIT ALARME GPRS</b></p>

                        <ul className={"browser-default"}>
                            <li>01 Central c/ Módulo GPRS;</li>
                            <li>02 Sirenes;</li>
                            <li>02 Leds;</li>
                            <li>01 Bateria;</li>
                            <li>10 Sensores;</li>
                            <li>02 Infras;</li>
                            
                        </ul>
                    </Col>
                </Row>
            </Card> </Col>
    </div>

    </Row>
);

export default Produtos;
