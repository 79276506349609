import React from "react";

import { Row, Col, Card, Slider, Slide} from 'react-materialize';

import empresa from '../../images/empresa.jpeg';
import mariapolis from '../../images/mariapolis-nova.jpeg';
{/*import mariapolis from '../../images/mariapolis.jpeg';*/}



const Empresa = () => (

    <Row>

        <Col m={5} s={12}>
        <div align={"justify"}>
        <h5 className="subtitle">Empresa</h5>
        <Card>

                <p><b>Nossa organização é totalmente legalizada</b> e cumpre rigorosamente todas as legislações e normativos que regem a segurança privada. Somos credenciados pela Brigada Militar-RS, no  GSVG (Grupamento de Supervisão de Vigilância e Guardas) e dispomos de Alvará de Autorização da Anatel para operar Rádio Frequência em todo estado do Rio Grande do Sul. </p>

<p><b>Possuímos mais de 20 funcionários efetivos</b>, em regime de trabalho à luz das normas da CLT, onde todos são contratados em rigoroso processo seletivo em que exigimos ficha limpa perante aos órgãos policiais e judiciais, visando sobre tudo a <b>proteção dos clientes e imagem da empresa</b>. É nosso compromisso e responsabilidade, manter em nosso quadro funcional, somente colaboradores com bons antecedentes e ótima conduta profissional e pessoal.</p>

<p>Também, somos a <b>única empresa</b> a possuir Base de Atendimento Operacional em <b>Atlântida Sul e Mariápolis</b>, dando maior agilidade e rapidez nas ocorrências.</p>

          </Card>
    </div>
        </Col>

        <Col m={7} s={8}>
            <Slider className={"slider"}>
                <Slide src={empresa} className={"img"} title="Atlântida Sul" placement="right">

                </Slide> 
                <Slide src={mariapolis} className={"img"} title="Mariápolis" placement="left">

                </Slide>
            </Slider>
        </Col>
    </Row>

  

);

export default Empresa;
