// Importantando o React
import React from "react";
// Importantando o component Home
import Home from "./components/home/home";
// Importantando o component Contact

import { Container } from 'react-materialize';
// Importanto o component <Switch /> e <Route /> da nossa Lib de rotas
import { Switch, Route } from 'react-router-dom'
import Contato from "./components/contato/contato";
import Empresa from "./components/empresa/empresa";
import Localizacao from "./components/localizacao/localizacao";
import Produtos from "./components/produtos/produtos";
import Serviços from "./components/servicos/servicos";

const Main = () => (
  <main>
    <Container>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route path="/empresa" component={Empresa}/>
        <Route path="/produtos" component={Produtos}/>
        <Route path="/localizacao" component={Localizacao}/>
        <Route path="/servicos" component={Serviços}/>
        <Route path="/contato" component={Contato}/>
      </Switch>
    </Container>
  </main>  
);

export default Main;