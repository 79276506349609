import React from "react";
import { Footer } from 'react-materialize';

import tocuidandotransparent from '../../images/to-cuidando-transparent.png';
import icon from '../../images/icon.jpeg';

const Footr = () => (
  <Footer
    className="red darken-2"
    copyrights="2024. Todos direitos reservados"
    links={
      <ul>
        <li>
          <a
            className="grey-text text-lighten-3"
            href="https://www.facebook.com/tocuidando"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
        </li>
        <li>
          <a
            className="grey-text text-lighten-3"
            href="https://tocuidando.com.br/contato"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contato
          </a>
        </li>
        <li>
          <a className="grey-text text-lighten-3" href="#!">
            (51) 98426-1163
          </a>
        </li>
        <li>
          <a className="grey-text text-lighten-3" href="#!">
            (51) 99692-0560
          </a>
        </li>
      </ul>
    }
    style={{
      position: "relative",
      bottom: "0",
      width: "100%",
      padding: "20px 10px",
      boxSizing: "border-box",
    }}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={icon}
        alt="Logo"
        style={{
          width: "80px", // Adjust size
          height: "80px", // Adjust size
          marginRight: "10px", // Add spacing between the logo and text
        }}
      />
      <h5 className="white-text" style={{ margin: "0" }}>
        
Tô Cuidando - Serviços de Zeladoria Ltda
      </h5>
    </div>
    <p className="grey-text text-lighten-4">Desde 1999 prestando serviços de Segurança Patrimonial, exclusivamente nas praias de Atlântida Sul e Mariápolis.</p>
  </Footer>
);

export default Footr;