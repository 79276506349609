// Importando o React
import React, { Component } from 'react';
// Importando o Component Header
import Header from './components/header/header'
// Importando o component Main
import Main from './main'


import Footr from './components/footer/footr'
class App extends Component {
  render() {
    return (
      <div>
        <Header></Header>
        <Main />
        <Footr/>
      </div>
    );
  }
}

export default App;