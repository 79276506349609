import React from "react";

import { Row, Col, Card, Button} from 'react-materialize';

import {Link} from 'react-router-dom';

import place from '../../images/place.png';

const Localizacao = () => (

    <div align="justify">
        
         <h5 className="subtitle">Tô Cuidando - Segurança Patrimonial</h5>
         
         
        <Row>
        <Col m={5} s={12}>
        
        <Card>
            <div align={"center"}>
                <img src={place} className={"icon"}/>
        <p><b>LOJA ATLÂNTIDA SUL</b></p>   
        <p>Rua Marambaia- 233</p>
        <p>Telefone: (51) 99692-0560</p>
        <p>CEP: 95520-000.</p>
        <p>Aberto 24H.</p>
        <Button color="secondary" className="button"><a href="https://www.google.com/maps/place/T%C3%B4+Cuidando+Seguran%C3%A7a+Patrimonial./@-29.865051,-50.08314,15z" color="#ffffff">Mapa</a>

        </Button>

        </div>
        
        </Card>
        </Col>

        <Col m={5} s={15}>
        
            <Card>
                <div align={"center"}>
                <img src={place} className={"icon"}/>
            <p><b>LOJA MARIÁPOLIS</b></p>   
            <p>Av Brasil- 412</p>
            <p>Telefone: (51) 98426-1163</p>
            <p>CEP: 95527-000</p>
            <p>Aberto 24H.</p>
            <Button color="secondary" className="button">Mapa

            </Button>
            </div>
            </Card>
        </Col>
        </Row>
        
    </div>

);

export default Localizacao;
