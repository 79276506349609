import React from "react";

import { Row, Col, Card } from 'react-materialize';

const Contato = () => (

    <Row>
        <h5 className="subtitle">Contato</h5>
        <form action={"https://formspree.io/aquassaude@gmail.com"} method={"POST"}>
                <input type={"text"} name={"name"} placeholder={"Nome"}/>
                <input type={"text"} name={"cell"} placeholder={"Telefone"}/>
                <input type={"email"} name={"_replyto"} placeholder={"Email"}/>
                <input type={"text"} name={"text"} placeholder={"Digite sua mensagem"}/>
                    <input type={"submit"} style={{backgroundColor: '#DC143C', color: 'white', border: '#DC143C'}} value={"ENVIAR"} className={"margin"}/>
                </form>
    </Row>

);

export default Contato;
