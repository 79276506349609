import React from "react";

import { Row, Col, Card, Slider, Slide } from 'react-materialize';

import placa1 from '../../images/placa1.jpg';
import placa2 from '../../images/placa2.jpg';
import placa3 from '../../images/placa3.jpg';
import placa4 from '../../images/placa4.jpg';
import placa5 from '../../images/placa5.jpg';
import placa6 from '../../images/placa6.png';

const Serviços = () => (
  <div style={{ padding: "20px" }}> {/* Add padding around the entire component */}
    <Row>
      <Col s={12}>
        <div style={{ textAlign: "justify" }}> {/* Better alignment using CSS */}
          <h5 className="subtitle">Serviços</h5>
          <Card>
            <ul className="browser-default">
              <li>
                Atendemos, <b>exclusivamente</b>, as praias de <b>Atlântida Sul</b> e <b>Mariápolis</b> de forma que possamos realizar um <b>serviço de qualidade</b>, com resposta imediata de nossos colaboradores, em caso de disparos de alarmes, cercas elétricas ou de movimentos suspeitos nas ruas e avenidas onde dispomos de monitoramento eletrônico através de câmeras de segurança ou de nossos vigias que circulam, diuturnamente, de carro ou moto realizando um trabalho preventivo em toda nossa área de atuação. Havendo necessidade acionamos forças policiais para apoio.
              </li>
            </ul>
          </Card>
        </div>
      </Col>
    </Row>

    <Row>
      <Col s={12}>
        <div style={{ textAlign: "justify", marginTop: "20px" }}>
          <h5>Placas</h5>
          <Card>
            <div style={{ display: "flex", gap: "15px", justifyContent: "center", flexWrap: "wrap" }}>
              <img src={placa6} className="placas" style={{ maxWidth: "300px", height: "auto" }} alt="Placa 6" />
            </div>
          </Card>
        </div>
      </Col>
    </Row>
  </div>
);

export default Serviços;
