// Importantando o React
import React from "react";
// Importando os components necessárias da lib react-materialize
import { Navbar, NavItem, Row} from 'react-materialize';
// Importando o Component NavLink da nossa lib de rotas
import NavLink from 'react-router-dom/es/NavLink';


const Header = () => (
  <Row>  
    <Navbar className="red darken-2">
      <li><NavLink to="/">Home</NavLink></li>
      <li><NavLink to="/empresa">Empresa</NavLink></li>
      <li><NavLink to="/servicos">Serviços</NavLink></li>
      <li><NavLink to="/produtos">Produtos</NavLink></li>
      <li><NavLink to="/localizacao">Localização</NavLink></li>
      <li><NavLink to="/contato">Contato</NavLink></li>
    </Navbar>
  </Row>
);

export default Header;