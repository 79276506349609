// Importando o React
import React from "react";
// Importando os components necessários da lib react-materialize
import { Row, Col, Card } from 'react-materialize';

import logo20anos from '../../images/logo20anos.png';
import logoTransparent from '../../images/logo-transparent.png';
import logo25anos from '../../images/logo25anos_2.jpg';

const Home = () => (
  <Row >
  
        
       
         {/* <img src={logo20anos} className={"bg"}/> */}
          
      <div className={"containerlogo"}> 
          <img src={logo25anos} className={"bg"} />
         
        </div>
        
   
  </Row>
);

export default Home;